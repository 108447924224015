import React, { useContext, useEffect, useState } from "react";
import styles from "./DefaultLayout.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import WhatsChangedPopUp from "../../organisms/WhatsChangedPopUp/WhatsChangedPopUp";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";
import MindbreezeAutoCompleteWithSearch from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch";
import FooterView from "../../organisms/FooterView/FooterView";
import HeaderRowOne from "../../organisms/HeaderRowOne/HeaderRowOne";
import MegaMenuView from "../../organisms/MegaMenu/MegaMenuView";
import { AdwFeatureSwitch } from "../../../types/FeatureSwitchTypes/AdwFeatureSwitch";
import moment from "moment";
import Alert from "../../organisms/Alert/Alert";
import {ReactComponent as BackToSiteIcon} from "../../../assets/images/chevrons-left.svg";
import AdwWhatChanged from "../../../types/WhatChangedTypes/AdwWhatChanged";

export interface IDefaultLayoutProps {

}

const DefaultLayout: React.FC<IDefaultLayoutProps> = () => {
  const [appState] = useContext<AdwContextType>(AdwContext);
  let location = useLocation();
  const [whatsChangedPopupFeatureEnabled, setWhatsChangedPopupFeatureEnabled] = useState(null);
  const [openWhatsChangedPopUp, setOpenWhatsChangedPopUp] = useState<boolean>(false);
  const whatsChangedData : AdwWhatChanged[] = appState.lists.whatChanged || [];
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const featureSwitchList: AdwFeatureSwitch[] =
      appState && appState.lists && appState.lists.featureSwitches
        && appState.lists.featureSwitches.length ? appState.lists.featureSwitches : [];
  const whatsChangedStatus =appState && appState.user && appState.user.whatsChangedStatus||null;
  const [isOrgChartView,setIsOrgChartView] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(()=>{ 
    if(location?.pathname?.toLowerCase()?.includes("/org-chart")){
      setIsOrgChartView(true);
    }else{
      setIsOrgChartView(false);
    }
    return () =>  setIsOrgChartView(false);
  },[location])

  const togglePop = () => {
    setOpenWhatsChangedPopUp(!openWhatsChangedPopUp);
  };


  useEffect(()=>{ // NOSONAR
    if(whatsChangedStatus || (whatsChangedData.length > 0)){
      let dueForDisplay = true;
      if(whatsChangedStatus){
        let whatsChangedStatusArray = whatsChangedStatus.split(',');
        let status = whatsChangedStatusArray[0];
        let date = whatsChangedStatusArray[1];
        if(status == 'close'){
        let haveNewData = whatsChangedData.filter(
          (whatChange) =>  moment.utc(whatChange.StartDate).diff(date,'days') > 0
        );
        if(haveNewData.length<1){
          dueForDisplay = false;
        }
        }
        if(status == 'remindmelater'){
          dueForDisplay = moment.utc(moment()).diff(date,'days') > 0;   
            }
      }
      if(!dueForDisplay || whatsChangedData.length < 1){
      setOpenWhatsChangedPopUp(false);
    }else{
        setOpenWhatsChangedPopUp(true);
    }
    }
  },[whatsChangedData,whatsChangedStatus])

  useEffect(() => {
    if (nwieID && featureSwitchList.length) {
      featureSwitchList.forEach((switchOption => {
        if (switchOption.feature === 'Whats Changed Popup' && switchOption.page === "Home" && switchOption.displayWidget && nwieID.match(switchOption.allowRegex)) {
          setWhatsChangedPopupFeatureEnabled(switchOption);
        }
      }))
    }
  }, [nwieID, featureSwitchList])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 1024 && isMobile) {
        setIsMobile(false);
      }
    }
    );
  }, [isMobile])

  return (
    <div className={styles.appWrapper}>
            {(whatsChangedPopupFeatureEnabled && openWhatsChangedPopUp) && 
            <WhatsChangedPopUp 
            toggle={togglePop} data={whatsChangedData} 
            disableRemindMeLater={whatsChangedPopupFeatureEnabled.featureSettings && whatsChangedPopupFeatureEnabled.featureSettings.disableRemindMeLater} />
            }
              {!isOrgChartView && 
            <div className={styles.headerWrapper}>
        <HeaderRowOne />
          <MegaMenuView />
            </div>
}
{isOrgChartView && 
<div>
          <Link className={styles.back_to_inside_link} to={"/employeeDetail"}>
          <BackToSiteIcon  className={styles.back_to_inside_icon} /> Back to associate details on InSide
          </Link>
          </div>
}
            <div className={styles.searchBoxDefault}>
        {!isOrgChartView && !isMobile && 
              <Alert />
            }
              <MindbreezeAutoCompleteWithSearch visibility={!isOrgChartView} />
        {!isOrgChartView && isMobile &&
          <Alert />
        }
        </div>
            <main className={styles.App} id="adw-app-container">
        <>
          {location.pathname === '/' && (
            <header className={styles.header}>
              <h1>Inside Homepage: Internal News and Resources for Nationwide Associates</h1>
            </header>
          )}
        </>
      <Outlet />
      
      </main>
 {!isOrgChartView && <FooterView />}
            </div>
  );
};

export default DefaultLayout;
