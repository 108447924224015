import React from "react";
import ArrowButton, { Direction } from "../../atoms/ArrowButton/ArrowButton";
import Link from "../../atoms/Link/Link";
import List from "../../atoms/List/List";
import styles from "./MegaMenuDropdown.module.scss";
import { MegaMenuCategory } from "../../../types/MegaMenuTypes/MegaMenuCategory";

export interface IMegaMenuDropdownProps {
  category: MegaMenuCategory;
  dropdownClasses: string;
  dropdownData: number;
  onSubCategoryClick: () => void;
  labelledby:string;
  menuIndex:number;
  mobileCollapsed:boolean;
  setMobileCollapsed: (i)=>void;
}

function handleListHeaderClick(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void {
  const subCategoryHeader: HTMLElement = e.currentTarget;
  const subCategoryHeaderData: string = subCategoryHeader.getAttribute(
    "data-subcategory-header"
  );
  const subCategory: any = subCategoryHeader.parentElement;
  const subCategoryList: HTMLElement = subCategory.querySelector(
    `[data-category-list="${subCategoryHeaderData}"]`
  );
  const categoriesHeight: number = getCategoryHeight();
  subCategoryList.style.left = "0";
  subCategoryList.style.minHeight = `${categoriesHeight}px`;
  subCategoryList.classList.add("subCategoryListOpen");
}

function handleSubCategoryHeaderClick(): void {
  const openSubCategory: any = document.querySelector(".subCategoryListOpen");
  openSubCategory.classList.remove("subCategoryListOpen");
  openSubCategory.removeAttribute("style");
}

// get first level navigation of mobile menu
function getCategoryHeight(): number {
  const categories: HTMLElement = document.getElementById("megaMenuCategories");
  const numberOfCategories: number = categories.childElementCount;
  const category: HTMLElement = categories.firstChild as HTMLEmbedElement;
  const categoryHeight: number = category.offsetHeight;
  return numberOfCategories * categoryHeight + 10;
}

const MegaMenuDropdown: React.FC<IMegaMenuDropdownProps> = ({
  dropdownClasses,
  category,
  dropdownData,
  onSubCategoryClick,
  labelledby,
  menuIndex,
  mobileCollapsed,
  setMobileCollapsed,
  ...otherProps
}) => {
  const classes: string = `${styles.megaMenuDropdown} ${dropdownClasses}`;
  const subCategoryHeaderClasses: string = `${styles.categoryHeaderContainer}`;

  return (
    <div id={`menuDropDown-${menuIndex}`} aria-labelledby={labelledby} className={classes} {...otherProps} data-dropdown={dropdownData}>
      <div className={subCategoryHeaderClasses} onClick={onSubCategoryClick}>
        <ArrowButton
          arrowDirection={Direction.LEFT}
          chevronClasses={styles.categoryHeaderChevron}
        />
        <h2 tabIndex={0} className={styles.categoryHeader}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              onSubCategoryClick();
            }
          }}
        >{category.categoryTitle}</h2>
      </div>
      <div className={styles.subCategories}>
        {category?.subCategories?.map(
            (subCategory, index) =>
              subCategory && (
                <div
                  key={subCategory.subCategoryTitle}
                  id={
                    subCategory.helpful.toLowerCase() === "yes"
                      ? `mm-special-subCategory-column-${index}`
                      : `mm-subCategory-column-${index}`
                  }
                  className={
                    subCategory.helpful.toLowerCase() === "yes"
                      ? `${styles.subCategory} ${styles.helpfulList}`
                      : `${styles.subCategory}`
                  }
                >
                  <h2
                  tabIndex={window.innerWidth <= 1024 ? 0 : -1}
                    className={styles.subCategoryHeader}
                    data-subcategory-header={index}
                    onClick={(e) => {
                      if (window.innerWidth > 1024) return;

                      const subCategoryList = e.currentTarget.nextElementSibling as HTMLUListElement;
                      if (subCategoryList) {
                        subCategoryList.style.display = 'block';
                      }
                      handleListHeaderClick(e)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        const subCategoryList = e.currentTarget.nextElementSibling as HTMLUListElement;
                        if (subCategoryList) {
                          subCategoryList.style.display = 'block';
                        }
                        handleListHeaderClick(e);
                      }
                    }}
                    id={
                      subCategory.helpful.toLowerCase() === "yes"
                        ? `mm-special-subCategory-title-${index}`
                        : `mm-subCategory-title-${index}`
                    }
                  >
                    {subCategory.subCategoryTitle}
                  </h2>
                <List listClasses={styles.subCategoryList} listData={index} style={{ display: window.innerWidth <= 1024 ? 'none' : 'block' }}>
                    <li
                      tabIndex={0}
                      className={styles.subCategoryListHeaderContainer}
                      onClick={(e) => {
                        handleSubCategoryHeaderClick()
                        const subCategoryList = e.currentTarget.parentElement as HTMLUListElement;
                        setTimeout(() => {
                          if (subCategoryList) {
                            subCategoryList.style.display = 'none';
                          }
                        }, 500)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleSubCategoryHeaderClick();
                          const subCategoryList = e.currentTarget.parentElement as HTMLUListElement;
                          setTimeout(() => {
                            if (subCategoryList) {
                              subCategoryList.style.display = 'none';
                            }
                          }, 500)
                        }
                      }}
                    >
                        <ArrowButton
                          arrowDirection={Direction.LEFT}
                          chevronClasses={styles.chevronStyles}
                          buttonClasses={styles.chevronButtonStyles}
                        />
                        <span className={styles.subCategoryListHeader}>
                          {subCategory.subCategoryTitle}
                      </span>
                    </li>
                  {subCategory?.subCategoryItems?.map(
                        (item, index) =>
                          item ? (
                            <li id={`menu-item-${index}`}
                              className={styles.subCategoryListItem}
                              key={item.id}
                              
                            >
                              <Link 
                                linkClasses={styles.subCategoryLink}
                                url={item.link.url}
                                target={item.windowTarget}
                                linkText={item.link.description}
                                onKeyDown={(e)=>{
                                 if(e.code==='Escape'){
                                    setMobileCollapsed(e);
                                  }
                                }}
                                
                                id={
                                  subCategory.helpful.toLowerCase() === "yes"
                                    ? `mm-special-subCategory-link-${index}`
                                    : `mm-subCategory-link-${index}`
                                }
                              />
                            </li>
                          ) : null
                      )}
                  </List>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default MegaMenuDropdown;
