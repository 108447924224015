
import React, { useState, useLayoutEffect, useEffect } from "react";
import Polyfills from "../../../assets/js/polyfills";
import MegaMenuCategories from "../../molecules/MegaMenuCategory/MegaMenuCategories";
import MobileMegaMenuHeader from "../../molecules/MobileMegaMenuHeader/MobileMegaMenuHeader";
import styles from "./MegaMenuView.module.scss";
import {isMobile, isTablet} from 'react-device-detect';

export interface IMegaMenuState {
  mobileCollapsed: boolean;
  adwLoginId: string;
  distanceFromLeft: number;
  onSearchPage: boolean;
  showSearch: boolean;
}

export interface IMegaMenuViewProps { }

const MegaMenuView: React.FC = () => {

  Polyfills.includes();
  const logoSrc: string = require("../../../assets/images/InSide.png");

  const [mobileCollapsed, setMobileCollapsed] = useState(true);
  const [distanceFromLeft, setDistanceFromLeft] = useState(0);


  useLayoutEffect(() => {
    setTimeout(() => {
      const distanceFromLeft: number = getDistanceFromLeftEdge();
      if (distanceFromLeft !== 0) {
        setDistanceFromLeft(distanceFromLeft)
      }
    }, 200);
    listenForResize();
  }, []);

  useEffect(()=>{
    let menuDropDownContainer = document.querySelectorAll('[id^="nav-button-"]');
    if(menuDropDownContainer){
      menuDropDownContainer.forEach(dropdownContainer=>{
        dropdownContainer.addEventListener('mouseleave',()=>{
          const dropdownOpen = getOpenDropdown();
          if (dropdownOpen && dropdownOpen.classList.contains("openDropdown")) {
            dropdownOpen.previousElementSibling.setAttribute("aria-expanded","false");
            closeDropdown("openDropdown", `${styles.dropdownOpen}`, dropdownOpen);
          }
        });
      });
    }
    return ()=>{
      if(menuDropDownContainer){
        menuDropDownContainer.forEach(dropdownContainer=>{
          dropdownContainer.removeEventListener('mouseleave',()=>{});
        });
      }
    }
  },[])

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && !mobileCollapsed) {
        handleHamburgerButtonClick();
      }
    })
  }, [mobileCollapsed]);

  const listenForResize = (): void => {
    window.addEventListener("resize", () => {
      setDistanceFromLeft(getDistanceFromLeftEdge)
    });
  };


  const getDistanceFromLeftEdge = (): number => {
    // reset the distance to 0 for an easier calculation
    setDistanceFromLeft(0);

    const megaMenuContainer: HTMLDivElement = document.getElementById(
      "megaMenuContainer"
    ) as HTMLDivElement;

    if (megaMenuContainer) {
      return -megaMenuContainer.getBoundingClientRect().left;
    } else {
      return 0;
    }
  };


  // open and close mobile menu
  const handleHamburgerButtonClick = (): void => {
    setMobileCollapsed(!mobileCollapsed);
    closeDropdown("openDropdownMobile", `${styles.dropdownOpenMobile}`);
    closeSubCategoryList();
  };

  // close third level of mobile menu
  const closeSubCategoryList = (): void => {
    const openSubCategory: any = document.querySelector(".subCategoryListOpen");
    if (openSubCategory !== null) {
      openSubCategory.classList.remove("subCategoryListOpen");
      openSubCategory.removeAttribute("style");
    }
  };

  // get first level navigation height of mobile menu
  const getCategoryHeight = (): number => {
    const categories: HTMLElement = document.getElementById(
      "megaMenuCategories"
    );
    const numberOfCategories: number = categories.childElementCount;
    const category: HTMLElement = categories.firstChild as HTMLEmbedElement;
    const categoryHeight: number = category.offsetHeight;
    return numberOfCategories * categoryHeight + 10;
  };

      // Desktop Mouse Click event
      const onClickOnCategory = (
        e:
        | React.MouseEvent<HTMLButtonElement>
      ): void => {
        const dropdownOpen = getOpenDropdown();
        if (dropdownOpen && dropdownOpen.classList.contains("openDropdown")) {
          dropdownOpen.previousElementSibling.setAttribute("aria-expanded","false");
          closeDropdown("openDropdown", `${styles.dropdownOpen}`, dropdownOpen);
        }
        const dropdown: HTMLElement = getTargetedDropdown(e);
        if(dropdown.getAttribute('id') != (dropdownOpen && dropdownOpen.getAttribute('id'))){
          if (dropdown.classList.contains("openDropdown")) {
            dropdown.previousElementSibling.setAttribute("aria-expanded", "false");
            closeDropdown("openDropdown", `${styles.dropdownOpen}`, dropdown);
          } else {
            dropdown.previousElementSibling.setAttribute("aria-expanded", "true");
            openDropdown("openDropdown", `${styles.dropdownOpen}`, dropdown);
          }
        }
      };

  // open and close navigation (first level mobile)
  const handleMegaMenuButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const targetDropdown: HTMLElement = getTargetedDropdown(e);
    if (targetDropdown.classList.contains("openDropdownMobile")) {
      closeDropdown(
        "openDropdownMobile",
        `${styles.dropdownOpenMobile}`,
        targetDropdown
      );
    } else {
      const openDropdownElement: HTMLElement = getOpenDropdown();
      if (openDropdownElement) {
        closeDropdown(
          "openDropdownMobile",
          `${styles.dropdownOpenMobile}`,
          openDropdownElement
        );
      }
      const categoriesHeight: number = getCategoryHeight();
      targetDropdown.style.minHeight = `${categoriesHeight}px`;
      openDropdown(
        "openDropdownMobile",
        `${styles.dropdownOpenMobile}`,
        targetDropdown
      );
    }
  };

  // figure out which dropdown is being targeted by an event on the page
  const getTargetedDropdown = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
  ): HTMLElement => {
    const buttonClick: HTMLElement = e.currentTarget;
    const buttonClickedData: string = buttonClick.getAttribute(
      "data-mega-button"
    );
    const category: any = buttonClick.parentElement;
    const dropdown: HTMLElement = category.querySelector(
      `[data-dropdown="${buttonClickedData}"]`
    );
    return dropdown;
  };

  const handleSubCategoryClick = (): void => {
    const dropdown: HTMLElement = getOpenDropdown();
    closeDropdown(
      "openDropdownMobile",
      `${styles.dropdownOpenMobile}`,
      dropdown
    );
  };

  // find the open dropdown
  const getOpenDropdown = (): HTMLElement => {
    let dropdown: HTMLElement = document.querySelector(
      ".openDropdown"
    );
    if (!dropdown) {
      dropdown = document.querySelector(".openDropdownMobile");
    }
    return dropdown;
  };

  // open the dropdown/first level of navigation
  const openDropdown = (
    className: string,
    dropdownClass: string,
    dropdown: HTMLElement
  ): void => {
    dropdown.classList.add(dropdownClass);
    dropdown.classList.add(className);
  };

  // close dropdown/first level of navigation
  const closeDropdown = (
    className: string,
    dropdownClass: string,
    dropdown?: HTMLElement
  ): void => {
    let openDropdown: HTMLElement;
    if (dropdown) {
      openDropdown = dropdown;
    } else {
      openDropdown = getOpenDropdown();
    }

    if (openDropdown) {
      openDropdown.classList.remove(dropdownClass);
      openDropdown.classList.remove(className);
    }
  };


  const containerStyles: React.CSSProperties = {
    left: distanceFromLeft,
  };

  if (isMobile) {
    containerStyles.position = "relative";
  }


  const spacerStyles: string = `${styles.megaMenuSpacer}`;

  return (
    <>
      <div
        className={ styles.megaMenuContainer}
        id="megaMenuContainer"
        style={containerStyles}
      >
        <div className={styles.headerInnerWrapper} id="new_navigation_bar">
        {logoSrc && (
          <MobileMegaMenuHeader
            logoSrc={logoSrc}
            mobileCollapsed={mobileCollapsed}
            handleButtonClick={handleHamburgerButtonClick}
          />
        )}
        <MegaMenuCategories
            handleButtonClick={(isTablet || isMobile || window.innerWidth <= 1024) ? handleMegaMenuButtonClick : onClickOnCategory}
          handleSubCategoryClick={handleSubCategoryClick}
          mobileCollapsed={mobileCollapsed}
          setMobileCollapsed={(e)=>{ const dropdown: HTMLElement = getTargetedDropdown(e);
            closeDropdown("openDropdown", `${styles.dropdownOpen}`, dropdown)}}
          closeMobileMegaMenu={handleHamburgerButtonClick}
        />
        </div>
      </div>
      
      <div className={spacerStyles} />
    
    </>

  );

};

export default MegaMenuView;
