import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwDateTimeService from "../../../services/AdwDateTimeService";
import PeopleUtils from "../../../utils/PeopleUtils";
import Office365Image from "../../atoms/Office365Image/Office365Image";
import styles from "./HeaderRowOne.module.scss";

const profileMenuArrow: string = require("../../../assets/images/profile-menu-arrow.svg").default;

const insideMainLogo: string = require("../../../assets/images/InSide.png");

const HeaderRowOne: React.FC = () => {
  const navigate = useNavigate();
  const [appState,dispatch]: AdwContextType = useContext(AdwContext);
  const [isOnMobile, setIsOnMobile] = useState<boolean>(false);
  const [hasPasswordNotification, setHasPasswordNotification] = useState<number>(0);
  const [hasMyTechNotification, setHasMyTechNotification] = useState<number>(0);
  const [openDropdown,setOpenDropdown]=useState<boolean>(false);
  const [formattedDate,setFormattedDate]=useState<string>(null);
  const today: Date = new Date();
  const monthName: string = AdwDateTimeService.getMonthName(today.getMonth());
  const dayName: string = AdwDateTimeService.getDayName(today.getDay());
 

  const checkIfMobile = () => {
    if (window.innerWidth <= 767 || window.screen.width <= 767) {
      setIsOnMobile(true);
   }
    else {
      setIsOnMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
    checkIfMobile();
    setFormattedDate(`${dayName}, ${monthName} ${today.getDate()}, ${today.getFullYear()}`);
    checkMyTechNotifications();
    if(openDropdown){
      window.addEventListener('keydown', (e)=>{
        if(e.code == 'Escape'){
          setOpenDropdown(false);
        }
      });
    }
    return () => {
      window.removeEventListener('resize', checkIfMobile);
      window.addEventListener('keydown', (e)=>{
        if(e.code == 'Escape'){
          setOpenDropdown(false);
        }
      });
    }
   });

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.id !== 'changeMyPassword'
        && target.id !== 'viewMyProfile'
        && target.id !== 'myTech'
        && target.id !== 'profileDropDownArrowImg'
        && target.id !== 'profileDropDownArrow') {
        setOpenDropdown(false);
      }
    }

    if (openDropdown) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    }
  }, [openDropdown]);

  useEffect(() => {
    if(appState && appState.graphUser && appState.graphUser.lastPasswordChangeDateTime){
      
      checkPasswordChangeNotifications(appState.graphUser.lastPasswordChangeDateTime);
    }
   
   },[appState.graphUser])

  const checkPasswordChangeNotifications=(lastPasswordChangeDateTime)=>{
    let utcDate = moment.utc(lastPasswordChangeDateTime);
    let expires = utcDate.add(90,'days');
    let today = moment();
    let result = expires.diff(today,'days') <=10 ? 1 : 0;
    setHasPasswordNotification(result);
  }

  const checkMyTechNotifications=()=>{
  if(appState && appState.lists && appState.lists.myTechNotification[0] && appState.lists.myTechNotification[0].notificationData && appState.lists.myTechNotification[0].notificationData.notificationCount){
    setHasMyTechNotification(appState.lists.myTechNotification[0].notificationData.notificationCount);
  }
  }

  const openAssociateProfile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const nwie: string = appState && appState.user && appState.user.nwieID ? appState.user.nwieID : "";
    if (nwie) {
      setOpenDropdown(false);
      dispatch(UpdateViewUserDetailNwie(nwie));
      navigate("/employeeDetail");
    }
  };
  
  const collapseAndNavigate = (e: React.SyntheticEvent,url:string) => {
    e.preventDefault();
    if (url) {
      setOpenDropdown(false);
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };
  return (<>
    { (!isOnMobile ) &&
    <header className={styles.officeBarContainer} id="headerRowOne">
  <a className={styles.insideHeaderSkip}  title="skip to main content" href="#adw-app-container" >Skip to main content</a>
      <div className={styles.headerInnerWrapper}>
      <div className={styles.profileWrapper} id="profile-wrapper">
        <div className={styles.profileImageWrapper}>
                <Office365Image openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} />
        {(hasMyTechNotification + hasPasswordNotification) > 0  && 
        <div className={styles.notificationCountWrapper}>
          <span aria-label={`You have ${hasMyTechNotification + hasPasswordNotification} notification`}>
              {hasMyTechNotification + hasPasswordNotification}
          </span>
        </div>
      }
      </div>
      <nav className={styles.userGreetingWrapper} aria-label="Profile dropdown">
        <div className={styles.greetingWrapper}>
          <span className={`${styles.greetingText} ${styles.adjustedGreeting}`} aria-label={`Hello, ${PeopleUtils.formatAssociateDisplayName(appState?.graphUser?.displayName)}`}>
              Hello,
          </span>
          <button id="profileDropDownArrow" className={styles.dropdownIconWrapper}
            type="button"
            aria-haspopup="true"
            aria-controls="profileDropDownContent"
            aria-expanded={openDropdown} 
            onClick={() => { setOpenDropdown(!openDropdown) }}
            onFocus={() => {
              if (openDropdown) {
                setOpenDropdown(false)
              }
            }}
            aria-label="profile dropdown">
            <div
              className={`${styles.greetingText} ${openDropdown}`}
              id="profileDropDownArrow"
              aria-label={`Hello, ${PeopleUtils.formatAssociateDisplayName(appState?.graphUser?.displayName)}`}
            >
            {PeopleUtils.formatAssociateDisplayName(appState?.graphUser?.displayName)}
            </div>
            <img
              src={profileMenuArrow}
              className={`${styles.profileMenuArrow} ${openDropdown && styles.active}`}
              alt="profile dropdown"
              id="profileDropDownArrowImg"
              style={{ marginLeft: '8px' }} // Add space between displayName and icon
            />
          </button> 
        </div>
      <ul onMouseLeave={() => { setOpenDropdown(false);}}
        id="profileDropDownContent"
        aria-labelledby="profileDropDownArrow"
        style={{ display: openDropdown ? 'flex' : 'none' }}
        className={styles.profileDropdownWrapper}
      >
        <li className={styles.profileDropDownItem}>
          <Link
            onClick={(e) => collapseAndNavigate(e, 'https://password.nwie.net/Rebuild/NWChangePassword?page=start')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.code === 'Enter') {
                collapseAndNavigate(e, 'https://password.nwie.net/Rebuild/NWChangePassword?page=start');
              }
            }}
            to="#"
            id="profileDropDownItem"
            aria-label={`Change my password ${hasPasswordNotification > 0 ? 'You have ' + hasPasswordNotification + ' new notification' : ''}`}
            target="_blank"
          >
        <div className={styles.notificationPlaceholder}>
          {hasPasswordNotification > 0 && (
            <span className={styles.notificationCounterWrapper}>
              {hasPasswordNotification}
            </span>
          )}
        </div>
        <span className={styles.profileMenuItem} id="changeMyPassword">
          Change my password
        </span>
      </Link>
    </li>
    <li className={styles.profileDropDownItem}>
      <Link
        id="profileDropDownItem"
        onClick={openAssociateProfile}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.code === 'Enter') {
            openAssociateProfile(e);
          }
        }}
        to="#"
      >
        <div className={styles.notificationPlaceholder}></div>
        <span className={styles.profileMenuItem} id="viewMyProfile">
          View my profile
        </span>
      </Link>
    </li>
    <li className={styles.profileDropDownItem}>
      <Link
        onClick={(e) => collapseAndNavigate(e, 'https://mytech.nationwide.com/')}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.code === 'Enter') {
            collapseAndNavigate(e, 'https://mytech.nationwide.com/');
          }
        }}
        to="#"
        id="profileDropDownItem"
        target="_blank"
        aria-label={`My Tech ${hasMyTechNotification > 0 ? 'You have ' + hasMyTechNotification + ' new notifications' : ''}`}
      >
        <div className={styles.notificationPlaceholder}>
          {hasMyTechNotification > 0 && (
            <span className={styles.notificationCounterWrapper}>
              {hasMyTechNotification}
            </span>
          )}
        </div>
        <span id="myTech" className={styles.profileMenuItem}>
          MyTech
        </span>
      </Link>
    </li>
  </ul>
</nav>


      </div>
      <div className={styles.insideMainLogoWrapper}>
            <Link to="/" rel="noopener noreferrer" onFocus={() => setOpenDropdown(false)}>
          <img
              src={insideMainLogo}
              className={styles.insideMainLogo}
              alt="inside logo"
              />
                  </Link>
            </div>
            <div className={styles.dateMainWrapper}>
           
              <div className={styles.dateWrapper}>
         <span>{formattedDate}</span>
            </div>
            </div>
            </div>
  </header>
    }
    </>
  );
};

export default HeaderRowOne;
