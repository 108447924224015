import React, { useContext, useEffect } from "react";
import { SetHrFowDigital, SetHrFowLia, SetHrFowTotal } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService from "../../../../../services/HRConnectService";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ShowHide from "../../ShowHide/ShowHide";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./FutureOfWork.module.scss";

let visibilityTimeout;
const FutureOfWork: React.FC = () => { // NOSONAR

  const [appState, dispatch]: AdwContextType = useContext(AdwContext)
  const [hidden, setHidden] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);

  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);

  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []

  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'FoW' && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          return false;
        }
        return true;
      }))
    }
  }, [nwieID, switches])

  function fetchData(): void {
    const shouldFetch: boolean = appState && appState.hrConnect && appState.hrConnect.fow
      && appState.hrConnect.fow.total === null;
    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      // Set to 0 to make shouldFetch return false above and prevent future calls to API
      dispatch(SetHrFowDigital(0));
      dispatch(SetHrFowLia(0));
      dispatch(SetHrFowTotal(0))

      HRConnectService.getFow(appState.user.nwieID)
        .then(({ total, lia, digital, hasError }) => {
          dispatch(SetHrFowDigital(digital));
          dispatch(SetHrFowLia(lia));
          dispatch(SetHrFowTotal(total));
          if (hasError) {
            setHasDataError(true)
          }
        }).then(() => {
          setIsLoading(false)
        })
    }
  }

  function toggleVisibility(): void {
    if (hidden) {
      fetchData();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }

  const calculatePercentage = (hidden, isLoading, scoredNumber): string => {
    let percentage;
    if (hidden) {
      percentage = "XXX";
    } else if (isLoading) {
      percentage = "-%";
    } else {
      percentage = `${Math.floor(scoredNumber * 100)}%`;
    }
    return percentage;
  }

  const calculateFowTotalNumber = (hidden, appState): number => {
    let total: number;

    if (hidden) {
      total = 0;
    } else if (appState && appState.hrConnect && appState.hrConnect.fow && appState.hrConnect.fow.total > 0) {
      total = appState.hrConnect.fow.total;
    } else {
      total = 0;
    }
    return total;
  }

  const calculateFowDigitalNumber = (hidden, appState): number => {
    let digital: number;

    if (hidden) {
      digital = 0;
    } else if (appState && appState.hrConnect && appState.hrConnect.fow && appState.hrConnect.fow.digital > 0) {
      digital = appState.hrConnect.fow.digital;
    } else {
      digital = 0;
    }
    return digital;
  }

  const calculateFowLiaNumber = (hidden, appState): number => {
    let lia: number;
    if (hidden) {
      lia = 0;
    } else if (appState && appState.hrConnect && appState.hrConnect.fow && appState.hrConnect.fow.lia > 0) {
      lia = appState.hrConnect.fow.lia;
    } else {
      lia = 0;
    }
    return lia;
  }

  const total: number = calculateFowTotalNumber(hidden, appState);
  const digital: number = calculateFowDigitalNumber(hidden, appState);
  const lia: number = calculateFowLiaNumber(hidden, appState);

  const digitalPercent: string = calculatePercentage(hidden, isLoading, digital);
  const liaPercent: string = calculatePercentage(hidden, isLoading, lia);
  const totalPercent: string = calculatePercentage(hidden, isLoading, total);

  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>Future of Work</h3></div>
      {!isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} /> :
        <>
          <div className={`${widgetStyles.section} ${styles.guageSection}`}>
          <div className={styles.gaugeContainer}>
            <div className={styles.guage}>
              {!hidden && <div className={hidden ? `${widgetStyles.obfuscate} ${styles.dial}` : styles.dial} style={{ transform: `rotate(${total * 180}deg)` }} />}
              <div className={styles.guageMask}>
                <div className={hidden ? `${widgetStyles.obfuscate} ${styles.guageText}` : styles.guageText}>{totalPercent}</div>
              </div>
            </div>
          </div>
            <ShowHide featureTitle="Future of Work" showing={!hidden} toggle={toggleVisibility} ariaLabel="Hide/Show Future of Work completion progress."/>
          </div>
          <div className={widgetStyles.divider} />
          <div className={widgetStyles.section}>
            <div className={styles.meters}>
              <label htmlFor="hr-digital-meter" className={styles.meterLabel}>
                <span className={styles.meterText}>Technology</span>
                <span className={hidden ? `${widgetStyles.obfuscate} ${styles.meterPercent}` : styles.meterPercent}>{digitalPercent}</span>
              </label>
              <div id="hr-digital-meter" className={styles.meter}>
                {!hidden && <div className={hidden ? `${widgetStyles.obfuscate} ${styles.meterComplete}` : styles.meterComplete} style={{ width: digitalPercent }} />}
              </div>
              <label htmlFor="hr-lia-meter" className={styles.meterLabel}>
                <span className={styles.meterText}>Lead, Innovate, Adapt</span>
                <span className={hidden ? `${widgetStyles.obfuscate} ${styles.meterPercent}` : styles.meterPercent}>{liaPercent}</span>
              </label>
              <div id="hr-lia-meter" className={styles.meter}>
                {!hidden && <div className={hidden ? `${widgetStyles.obfuscate} ${styles.meterComplete}` : styles.meterComplete} style={{ width: liaPercent }} />}
              </div>
            </div>
          </div>
        </>
      }
      <div className={widgetStyles.buttonRow}>
        <a
          href="https://onyourside.sharepoint.com/sites/Future-of-Work-Center"
          target="_blank"
          rel="noopener noreferrer"
          className={widgetStyles.buttonLink}
        >
          Visit Future of Work
        </a>
      </div>
    </div >
  );
}

export default FutureOfWork;