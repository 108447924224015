"use client";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import AuthService, {
  AccessTokenMessage,
  DO_AUTH,
  REFRESH_IFRAME_ID
} from "./services/AuthService";
import QueryParamUtils from "./utils/QueryParamUtils";
import { AdwContextProvider, adwContextReducer, AdwContextState } from "./context/AdwContextTypes";
import AuthRedirect from "./components/organisms/Auth/AuthRedirect";
import { GraphAvatarData, GraphUser } from "./types/GraphTypes/GraphUser";
import moment from "moment";
import CachingService from "./services/CachingService";
import AdwAlert from "./types/AlertTypes/AdwAlert";
import AdwCampaignBanner from "./types/CampaignBannerTypes/AdwCampaignBanner";
import AdwCommonTool from "./types/CommonToolsTypes/AdwCommonTool";
import AdwPopularTool from "./types/PopularToolsTypes/AdwPopularTool";
import AdwNewsStory from "./types/CompanyNewsTypes/AdwNewsStory";
import AdwFeaturedContent from "./types/FeaturedContentTypes/AdwFeaturedContent";
import AdwFooterMenuItem from "./types/FooterTypes/AdwFooterMenuItem";
import { MegaMenuCategory } from "./types/MegaMenuTypes/MegaMenuCategory";
import MainErrorMessage from "./components/organisms/MainErrorMessage/MainErrorMessage";
import AdwHrConnectLink from "./types/FooterTypes/AdwHrConnectLink";
import AdwAtoZView from "./types/AtoZViewTypes/AdwAtoZView";
import { AdwHrConnectSwitch } from "./types/HRConnectTypes/AdwHrConnectSwitch";
import AdwCalendarEventType from "./types/CalendarTypes/AdwCalendarEventType";
import { AdwFeatureSwitch } from "./types/FeatureSwitchTypes/AdwFeatureSwitch";
import BrowserUtils from "./utils/BrowserUtils";
import AdwFcEvent from "./types/CalendarTypes/AdwFcEvent";
import RequestBody from "./types/SearchTypes/RequestBody";
import { ErrorBoundary } from "react-error-boundary";
import ErrorReportingService from "./services/ErrorReportingService";
import AdwWhatChanged from "./types/WhatChangedTypes/AdwWhatChanged";
import AdwAnnouncement from "./types/AnnouncementsTypes/AdwAnnouncement";
import * as MsalAuthService from "./services/MsalAuthService";
import TimingUtils from "./utils/TimingUtils";

const AppSetup: React.FC = () => { // NOSONAR
  
  if (!BrowserUtils.isIframe()) {
    sessionStorage.setItem('appLoadStart', Date.now().toString());
    sessionStorage.setItem('registeredComponents', '[]');
    TimingUtils.clearRegisteredComponents();
  }

  const handleVisibilityChange = () => {
    AuthService.handleVisibilityChange();
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  })

  useEffect(() => {
    window.addEventListener("message", handleAuthMessage, false);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, []);

  /**
   * This function receives authentication information from the hidden iframe below,
   * which is used to do authentication, and updates the AuthService so that the
   * received authentication information can be used
   *
   * @param event Event containing authentication information
   */
  const handleAuthMessage = (event: MessageEvent): void => {
    if (event.origin.indexOf(window.location.origin) > -1) {
      const data: AccessTokenMessage = event.data;
      if (
        data.access_token ||
        data.token_type ||
        data.scope ||
        data.expires_at ||
        data.error ||
        data.id_token ||
        data.jwt
      ) {
        AuthService.ApigeeAuth.access_token = data.access_token
          ? data.access_token
          : AuthService.ApigeeAuth.access_token;
        AuthService.ApigeeAuth.token_type = data.token_type
          ? data.token_type
          : AuthService.ApigeeAuth.token_type;
        AuthService.ApigeeAuth.scope = data.scope
          ? data.scope
          : AuthService.ApigeeAuth.scope;
        AuthService.ApigeeAuth.expires_at = data.expires_at
          ? moment(data.expires_at)
          : AuthService.ApigeeAuth.expires_at;
        AuthService.ApigeeAuth.id_token = data.id_token
          ? data.id_token
          : AuthService.ApigeeAuth.id_token;
        AuthService.ApigeeAuth.jwt = data.jwt
          ? data.jwt
          : AuthService.ApigeeAuth.jwt;
        AuthService.ApigeeAuth.error = data.error
          ? data.error
          : AuthService.ApigeeAuth.error;
      }
      else if(AuthService.hasActiveSession()){
        /* if already authenticated, get the values from session */
        AuthService.ApigeeAuth.access_token = sessionStorage.getItem("access_token");
        AuthService.ApigeeAuth.token_type = sessionStorage.getItem("token_type");
        AuthService.ApigeeAuth.scope = sessionStorage.getItem("scope");
        AuthService.ApigeeAuth.expires_at = moment(sessionStorage.getItem("expires_at"));
        AuthService.ApigeeAuth.id_token = sessionStorage.getItem("id_token");
        AuthService.ApigeeAuth.jwt = sessionStorage.getItem("jwt");
        AuthService.ApigeeAuth.error = sessionStorage.getItem("apigee_error");
      }
    }
  };

  const alerts: [AdwAlert[], boolean] =
    CachingService.getLocalObjectByKey<AdwAlert[]>("Alerts");
  const aToZView: [AdwAtoZView[], boolean] =
    CachingService.getLocalObjectByKey<AdwAtoZView[]>("AToZ");
  const nextFourEvent: [AdwFcEvent[], boolean] =
  CachingService.getLocalObjectByKey<AdwFcEvent[]>("next four event");
  const nextFivePayday: [AdwFcEvent[], boolean] =
    CachingService.getLocalObjectByKey<AdwFcEvent[]>("next five payday");
  const fcCompanyEvent: [AdwFcEvent[], boolean] =
    CachingService.getLocalObjectByKey<AdwFcEvent[]>("Company Event");
  const companyEventType: [AdwCalendarEventType[], boolean] =
    CachingService.getLocalObjectByKey<AdwCalendarEventType[]>("Company Event Type");
  const campaignBanner: [AdwCampaignBanner[], boolean] =
    CachingService.getLocalObjectByKey<AdwCampaignBanner[]>(
      "Campaign Banner"
    ) || [[], true];
  const commonTools: [AdwCommonTool[], boolean] =
    CachingService.getLocalObjectByKey<AdwCommonTool[]>("Common Tools");
  const popularTools: [AdwPopularTool[], boolean] =
    CachingService.getLocalObjectByKey<AdwPopularTool[]>("Popular Tools");
  const whatChanged: [AdwWhatChanged[], boolean] =
    CachingService.getLocalObjectByKey<AdwWhatChanged[]>("What Changed");
  const companyNews: [AdwNewsStory[], boolean] =
    CachingService.getLocalObjectByKey<AdwNewsStory[]>("Company News");
  const announcements: [AdwAnnouncement[], boolean] =
    CachingService.getLocalObjectByKey<AdwAnnouncement[]>("Announcements");
  const featuredContent: [AdwFeaturedContent[], boolean] =
    CachingService.getLocalObjectByKey<AdwFeaturedContent[]>(
      "Featured Content"
    );
  const footerNavigation: [AdwFooterMenuItem[], boolean] =
    CachingService.getLocalObjectByKey<AdwFooterMenuItem[]>(
      "Footer Navigation"
    );
  const siteNavigation: [MegaMenuCategory[], boolean] =
    CachingService.getLocalObjectByKey<MegaMenuCategory[]>("Site Navigation");
  const hrConnectLinks: [AdwHrConnectLink[], boolean] =
    CachingService.getLocalObjectByKey<AdwHrConnectLink[]>("HRConnect Links");
  const hrConnectSwitches: [AdwHrConnectSwitch[], boolean] =
    CachingService.getLocalObjectByKey<AdwHrConnectSwitch[]>("HRConnect Switches");
  const featureSwitches: [AdwFeatureSwitch[], boolean] =
    CachingService.getLocalObjectByKey<AdwFeatureSwitch[]>("Feature Switches");

  const getInitialContextState = (): AdwContextState => {
    const tomorrow: Date = new Date(); // Actually today
    tomorrow.setDate(tomorrow.getDate() + 1); // Now tomorrow
    let defaultSearchTerm: string = QueryParamUtils.getParameter("searchTerm")!;
    const state: AdwContextState = {
      viewUserDetails: QueryParamUtils.getParameterByName("nwieID", ""),
      viewUserOrgChart:null,
      viewAvatarOrgChart: {} as GraphAvatarData,
      search: {
        query: "",
        searchPage: false,
        searchRequest: new RequestBody(
          defaultSearchTerm,
          0,
          10,
          [
            "*",
            "title as title",
            "uri as uri",
            "table as table",
            "SCOPETEASER(text, fragment=true, numFragments=4, fragmentScope=sentence) as teaser",
            "SCOPETEASER(text, fragment=true, numFragments=1, fragmentSize=1) as text",
            "img.uri.preview as previewImageUri",
            "img.uri.thumbnail as thumbnailImageUri",
            "latitude as latitude",
            "longitude as longitude",
            "morelikethisquery as morelikethisquery",
            "mimetype as mimetype",
            "sourcepath as sourcepath",
            "tags"
          ],
          [],
          []
        ),
      },
      user: null,
      isAdmin: false,
      graphUser: {} as GraphUser,
      lists: {
        alerts: alerts[0] ? alerts[0] : [],
        myTechNotification:[],
        aToZView: aToZView[0] ? aToZView[0] : [],
        nextFourEvent:nextFourEvent[0]?nextFourEvent[0]:[],
        nextFivePayday:nextFivePayday[0]?nextFivePayday[0]:[],
        fcCompanyEvent: fcCompanyEvent[0]?fcCompanyEvent[0]:[],
        companyEventType: companyEventType[0] ? companyEventType[0] : [],
        campaignBanner: campaignBanner[0] ? campaignBanner[0] : [],
        commonTools: commonTools[0] ? commonTools[0] : [],
        popularTools: popularTools[0]?popularTools[0]:[],
        whatChanged: whatChanged[0]?whatChanged[0]:[],
        companyNews: companyNews[0] ? companyNews[0] : [],
        announcements: announcements[0] ? announcements[0] : [],
        featuredContent: featuredContent[0] ? featuredContent[0] : [],
        footerNavigation: footerNavigation[0] ? footerNavigation[0] : [],
        mailCodeFloor: [],
        nwLocationName: [],
        siteNavigation: siteNavigation[0] ? siteNavigation[0] : [],
        hrConnectLinks: hrConnectLinks[0] ? hrConnectLinks[0] : [],
        hrConnectSwitches: hrConnectSwitches[0] ? hrConnectSwitches[0] : [],
        featureSwitches: featureSwitches[0] ? featureSwitches[0] : [],
        alertsExpired: alerts[1],
        aToZViewExpired: aToZView[1],
        campaignBannerExpired: campaignBanner[1],
        commonToolsExpired: commonTools[1],
        popularToolsExpired: popularTools[1],
        whatChangedExpired: whatChanged[1],
        companyEventExpired: fcCompanyEvent[1],
        companyEventTypeExpired: companyEventType[1],
        companyNewsExpired: companyNews[1],
        featuredContentExpired: featuredContent[1],
        footerNavigationExpired: footerNavigation[1],
        siteNavigationExpired: siteNavigation[1],
        hrConnectLinksExpired: hrConnectLinks[1]
      },
      hrConnect: {
        yourTime: {
          current: {
            hours: null,
            days: null,
          },
          cumulative: {
            hours: null,
            days: null,
          }
        },
        myPay: {
          net: null,
          nextPayDay: null
        },
        jobs: {
          openJobs: null,
          activeApps: null
        },
        fow: {
          total: null,
          lia: null,
          digital: null
        },
        benefitElections: {
          dentalCoverage: null,
          dentalPlan: null,
          endDateOE: null,
          enrollmentStatus: null,
          finalizeDateOE: null,
          hsaAmount: null,
          hsaCoverage: null,
          hsaPlan: null,
          medicalCoverage: null,
          medicalPlan: null,
          oeUrl: null,
          shortName: null,
          startDateOE: null,
          visionCoverage: null,
          visionPlan: null
        },
        retirementSavingsElections: {
          employeePct: null,
          retirementPlanID: null,
          retirementPlanName: null
        },
        associateWellbeingSafety: {
          nurseName: null,
          nursePhone: null,
          nurseEmail: null,
          counselorName: null,
          counselorPhone: null
        },
        myHealthProgram: {
          awardEarning: null,
          awardMedia: null,
          awardType: null,
          dob: null,
          emplid: null,
          firstName: null,
          hireDate: null,
          isEnrolled: null,
          lastName: null,
          maxAllowed: null,
          newHireCutoffDate: null,
          progEndDate: null,
          warningDays: null,
        }
      }
    };
    return state;
  };

  return !!QueryParamUtils.getParameter(DO_AUTH) ? (
    <AuthRedirect />
    ) : (
    <>
      <div>
        {AuthService.hasActiveSession() === false && (
          <iframe
            id={REFRESH_IFRAME_ID}
            title="auth_iframe"
            src={AuthService.getAzureAuthUrl()}
            style={{ display: "none" }}
            onLoad={() => {
              const authframe = document.getElementById(REFRESH_IFRAME_ID) as HTMLIFrameElement;
              if (authframe?.contentWindow) {
                const account = MsalAuthService.myMSALObj.getAccountByHomeId(MsalAuthService.myMSALObj.getAllAccounts()[0]?.homeAccountId)
                authframe.contentWindow.postMessage(
                  JSON.stringify({
                    method: 'POST',
                    body: JSON.stringify({ login_hint: localStorage.getItem('userPrincipalName') ?? account?.username })
                  }),
                  window.location.origin
                );
              }
            }}
          />
         )}
      </div>
      <AdwContextProvider
        initialState={getInitialContextState()}
        reducer={adwContextReducer}
        >
        <App />
      </AdwContextProvider>
    </>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ErrorBoundary FallbackComponent={MainErrorMessage} onError={(error,info)=>{ 
  ErrorReportingService.reportErrorWithMessage(
  error.toString(),
  "ErrorBoundary.tsx -> componentDidCatch -> AppSetup",
 info // appState.user.nwieID
)}}>
<AppSetup />
</ErrorBoundary>);
// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();