"use client";
import React, { useEffect, useContext, useState } from "react";
import CompanyNewsView from "../../organisms/CompanyNews/CompanyNewsView";
import Events from "../../organisms/Events/Events";
import styles from "./home.module.scss";
import ComponentErrorMessage from "../../organisms/ComponentErrorMessage/ComponentErrorMessage";
import HRConnect from "../../organisms/HRConnect/HRConnect";
import EnvironmentService from "../../../services/EnvironmentService";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";
import AtoZWidget from "../../organisms/AtoZ/AtoZWidget";
import ErrorReportingService from "../../../services/ErrorReportingService";
import { ErrorBoundary } from "react-error-boundary";
import { AdwFeatureSwitch } from "../../../types/FeatureSwitchTypes/AdwFeatureSwitch";
import CommonToolsMoved from "../../organisms/CommonToolsMoved/CommonToolsMoved";

// Background Images to be passed to error boundaries
const EventsBGImage: string = require("../../../assets/images/calendar-gray-icon.svg").default;
const AtoZBGImage: string = require("../../../assets/images/star-gray-icon.svg").default;

export interface IAdwPageProps {
  className?: string;
}

const HomePage: React.FC<IAdwPageProps> = ({ className }) => {
  document.title = "InSide";
  const [appState]: AdwContextType = useContext(AdwContext);
  const employeeType: string = appState && appState.user && appState.user.employeetype ? appState.user.employeetype : "";
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const featureSwitchList: AdwFeatureSwitch[] =
      appState && appState.lists && appState.lists.featureSwitches
        && appState.lists.featureSwitches.length ? appState.lists.featureSwitches : [];
  const [aZToolsMovedEnabled, setAZToolsMovedEnabled] = useState(null);
  const [commonToolsMovedEnabled, setCommonToolsMovedEnabled] = useState(null);
  const [announcementsEnabled, setAnnouncementsEnabled] = useState(null);

  useEffect(() => { // NOSONAR
      if (nwieID && featureSwitchList.length) {
        featureSwitchList.forEach((switchOption => {
           if (switchOption.feature === 'Component Moved' && switchOption.displayWidget && RegExp(switchOption.allowRegex).exec(nwieID)) {
            if(switchOption.featureSettings && switchOption.featureSettings.length > 0){
              switchOption.featureSettings.map((setting)=>{
                if(setting.component === "ATOZFAVORITES" && setting.enabled && RegExp(setting.allowRegex).exec(nwieID)){
                  setAZToolsMovedEnabled(setting);
                }
                if(setting.component === "MYTOOL" && setting.enabled && RegExp(setting.allowRegex).exec(nwieID)){
                  setCommonToolsMovedEnabled(setting);
                }
              })
            }
           }
           if (switchOption.feature === 'Announcements Widget' && switchOption.displayWidget && RegExp(switchOption.allowRegex).exec(nwieID)) {
            setAnnouncementsEnabled(switchOption)
           }
        }))
      }
    }, [nwieID, featureSwitchList]);

  return (
    <>
      <div className={className}>
        <div className={styles.appLayoutContainer}>
          <CompanyNewsView
            storySnapshotGrid={styles.storySnapshot}
            moreNewsGrid={styles.moreNews}
            featuredStoryGrid={styles.featuredStory}
            announcementsGrid={styles.announcements}
            allAnnouncementsGrid={styles.allAnnouncements}
          />
          <ErrorBoundary fallback={<ComponentErrorMessage
            parentClass={styles.events}
            heading="Events"
            bgImageSrc={EventsBGImage}
          />} onError={(error,info)=>{ 
            ErrorReportingService.reportErrorWithMessage(
            error.toString(),
            "ErrorBoundary.tsx -> CallupErrorMessage -> Events",
            appState.user.nwieID
          )}}>
            {(commonToolsMovedEnabled || announcementsEnabled)
              ? <Events eventsGrid={styles.events} />
              : <Events eventsGrid={styles.eventsOld} />
            }
          </ErrorBoundary>
          {aZToolsMovedEnabled ?
<CommonToolsMoved id="AtoZMoved" feature={aZToolsMovedEnabled}
commonToolsStyles={` ${styles.favorites}`}
/>
:
          <ErrorBoundary fallback={<ComponentErrorMessage
            parentClass={styles.favorites}
            heading="A - Z Favorites"
            bgImageSrc={AtoZBGImage}
          />} onError={(error,info)=>{ 
            ErrorReportingService.reportErrorWithMessage(
            error.toString(),
            "ErrorBoundary.tsx -> CallupErrorMessage -> AtoZWidget",
            appState.user.nwieID
          )}}>
              {(commonToolsMovedEnabled || announcementsEnabled)
                ? <AtoZWidget aToZGrid={styles.favorites} />
                : <AtoZWidget aToZGrid={styles.favoritesOld} />
              }
          </ErrorBoundary>
}
        </div>
      </div >
      {EnvironmentService.showHRConnect(employeeType) &&
        <ErrorBoundary fallback={<ComponentErrorMessage
          parentClass={styles.hrconnect}
          heading="HRConnect"
          bgImageSrc={null}
        />} onError={(error,info)=>{ 
          ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> CallupErrorMessage -> HRConnect",
          appState.user.nwieID
        )}}>
          <HRConnect />
        </ErrorBoundary>
      }
    </>
  );
};

export default HomePage;