import { useRef, useEffect } from 'react';

const useFocusTrap = (isOpen: boolean, shouldFocusFirstElement: boolean = false) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();

      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      // Focus on the first focusable element if the condition is met
      if (shouldFocusFirstElement) {
        firstElement.focus();
      }

      const handleTab = (e) => {
        if (e.key === 'Tab') {
          if (e.shiftKey) {
            // Shift + Tab
            if (document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            }
          } else {
            // Tab
            if (document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      modalRef.current.addEventListener('keydown', handleTab);

    }
  }, [isOpen, shouldFocusFirstElement]);

  const focusFirstElement = () => {

    if (modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      
      const firstElement = focusableElements[0];

      if (firstElement) {
        firstElement.focus();
      }
    }
  };

  return { modalRef, focusFirstElement };
};

export default useFocusTrap;
