import React, { useContext, useEffect, useState } from "react";
import classNames from 'classnames';
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwAlert from "../../../types/AlertTypes/AdwAlert";
import styles from "../../../App.module.scss";


import primaryImage from "../../../assets/images/alert-triangle-icon.svg";

export function determineAlertType(alertType: string) {
  const lowercaseType = alertType ? alertType.toLowerCase() : "";
  switch (lowercaseType) {
    case "primary (red)":
      return styles.alertPrimary;
    case "secondary (teal)":
      return styles.alertSecondary;
    case "dark blue":
      return styles.alertDarkBlue;
    case "accent blue":
      return styles.alertAccentBlue;
    case "purple":
      return styles.alertPurple;
    default:
      return styles.alertDefault;
  }
}

const Alert: React.FC = () => {
  const [appState]: AdwContextType = useContext(AdwContext);
  const [alertList, setAlertList] = useState<AdwAlert[] | null>(null);

  useEffect(() => {
    if (appState?.lists?.alerts && appState.lists.alerts.length > 0) {
      setAlertList([...appState.lists.alerts]);
    }
  }, [appState?.lists]);

  return alertList ? (
    <div id='alert-container' className={styles.alertWrapper}>
      {alertList.map((alert) => {
        const hasUrl = alert.AlertLink?.Url;

        const AlertComponent = hasUrl ? 'a' : 'div';
        const alertProps = hasUrl ?
          {
            href: alert.AlertLink.Url,
            target: alert.Target && alert.Target.toLowerCase() === "new" ? "_blank" : "_self",
            tabIndex: 0
          } :
          {};

        return (
          <AlertComponent
            {...alertProps}
            className={classNames(
              styles.alertContainer,
              determineAlertType(alert.AlertType),
              { [styles.cursorPointer]: hasUrl }
            )}
            id="alertLink"
            key={`alert_link_${alert.Id}`}
          >
            <div className={styles.alertMessageContainer}>
              <img
                className={styles.alertImage}
                alt={"Warning! Triangle with exclamation point"}
                src={primaryImage}
                id={`alertImage${alert.Id}`}
              />
              <p role="alert" aria-live="assertive" className={styles.alertMessage} id={`alertMessage${alert.Id}`}>
                {alert.AlertMessage}
              </p>
            </div>
          </AlertComponent>
        )
      })}
    </div>
  ) : null;
}

export default Alert;